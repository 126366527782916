"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.VKPHEmitter = void 0;
var message_1 = require("./message");
var VKPHEmitter = /** @class */ (function () {
    function VKPHEmitter() {
        this.listenersHashMap = new Map();
    }
    VKPHEmitter.prototype.init = function () {
        var _this = this;
        var listener = function (event) {
            var data = event.data;
            var currentMessageType = data.messageType;
            if (message_1.messageType[currentMessageType]) {
                _this.emit(currentMessageType, data);
            }
        };
        window.addEventListener('message', listener);
    };
    VKPHEmitter.prototype.on = function (event, fn) {
        var _this = this;
        var listeners = this.listenersHashMap.get(event);
        if (!listeners) {
            listeners = new Set();
            this.listenersHashMap.set(event, listeners);
        }
        listeners.add(fn);
        return function () {
            _this.off(event, fn);
        };
    };
    VKPHEmitter.prototype.off = function (event, fn) {
        var listeners = this.listenersHashMap.get(event);
        if (listeners) {
            listeners.delete(fn);
        }
        if (listeners && listeners.size === 0) {
            this.listenersHashMap.delete(event);
        }
    };
    VKPHEmitter.prototype.emit = function (event, payload) {
        var listeners = this.listenersHashMap.get(event);
        if (listeners) {
            listeners.forEach(function (listener) { return listener(payload); });
        }
    };
    VKPHEmitter.prototype.removeAllListeners = function (event) {
        this.listenersHashMap.delete(event);
    };
    return VKPHEmitter;
}());
exports.VKPHEmitter = VKPHEmitter;
