import { Achievement, UserAvatar } from '@vkph/components';
import { UiTypography, UiModal, UiSpace } from '@vkph/ui';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

import { GamificationAddedThank, GamificationBadges } from '@vkph/common/types/models';
import { getRoutePath, RouteNames, getFormattedDate } from '@vkph/common/utils';
import { useSpace } from '@vkph/ui/hooks';
import { getModalStepsForSingleTitle } from '@vkph/ui/utils';

import { ThanksHeader } from './thanks-header/ThanksHeader';

type ThankProps = { thank: GamificationAddedThank };
type BadgeProps = { badge: GamificationBadges };

export type GamificationThankPostProps = (ThankProps | BadgeProps) & {
  onClose?: () => void;
};

export const BadgeThanksView: FC<GamificationThankPostProps> = (props) => {
  const { onClose } = props;
  const { spaceXL } = useSpace({ unit: 'px' });

  let thank;
  let badge;

  if ('thank' in props) {
    const { thank: thankData } = props;

    thank = thankData;
  }

  if ('badge' in props) {
    const { badge: badgeData } = props;

    badge = badgeData;
  }

  const title = badge ? 'Награда' : 'Благодарность';

  return (
    <UiModal.Content>
      <Achievement.Placeholder borderRadius={8}>
        <UiModal.Header>
          <UiModal.Header.Title steps={getModalStepsForSingleTitle('')} style={{ padding: 0 }} />
        </UiModal.Header>
        <UiSpace full align="center" direction="vertical" style={{ padding: `0 ${spaceXL} ${spaceXL}` }}>
          <UiTypography.Title level={3} style={{ marginTop: 16 }}>
            {title}
          </UiTypography.Title>
          {badge && (
            <Achievement
              align="center"
              direction="vertical"
              title={badge.badge.name}
              paragraph={badge.badge.description}
              style={{ padding: 0 }}
            >
              <Achievement.Icon
                type="badge"
                src={badge.badge.image}
                badge={getFormattedDate(badge.awardedAt, 'date')}
                coins={badge.badge.coins > 0 ? badge.badge.coins.toString() : ''}
              />
            </Achievement>
          )}
          {thank && (
            <>
              <ThanksHeader thank={thank} />

              <Achievement
                align="center"
                direction="vertical"
                paragraph={thank.msg}
                title={thank.attributes?.name}
                style={{ padding: 0 }}
              >
                <Achievement.Icon
                  type="thank"
                  src={thank.attributes.image}
                  coins={thank.coins > 0 ? thank.coins.toString() : ''}
                >
                  <Link to={getRoutePath(RouteNames.Profile, { id: thank.fromUser.id })} onClick={onClose}>
                    <UserAvatar size={80} src={thank.fromUser.avatar} isActive={thank.fromUser.isActive} />
                  </Link>
                  <Link to={getRoutePath(RouteNames.Profile, { id: thank.toUser.id })} onClick={onClose}>
                    <UserAvatar size={80} src={thank.toUser.avatar} isActive={thank.toUser.isActive} />
                  </Link>
                </Achievement.Icon>
              </Achievement>
            </>
          )}
        </UiSpace>
      </Achievement.Placeholder>
    </UiModal.Content>
  );
};
