import { UiEmpty } from '@vkph/ui';
import React, { FC, PropsWithChildren, useMemo } from 'react';
import { AuthProvider } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';

import { useLocalStorage } from '@vkph/common/hooks';
import { SettingsAuthModel } from '@vkph/common/types/models';

import { AuthServiceProps } from '../../../typings';
import { AuthOIDC } from './Auth@oidc';
import { getRedirectParams } from './utils';

export const AuthServiceOIDC: FC<PropsWithChildren<AuthServiceProps>> = (props) => {
  const [authData] = useLocalStorage<SettingsAuthModel | null>('settingsAuthData', null);
  const navigate = useNavigate();

  if (!authData) {
    return <UiEmpty.Feed emptyMessage={{ header: 'Произошла ошибка авторизации' }} />;
  }

  const oidcConfig = useMemo(() => {
    const { NODE_ENV, REMOTE_URL } = process.env;
    const { clientId, realm, uri, domain } = authData;
    const authDomain = REMOTE_URL && NODE_ENV === 'development' ? REMOTE_URL : domain;

    return {
      authority: `${authDomain}${uri}realms/${realm}`,
      client_id: clientId || '',
      redirect_uri: window.location.href || '',
      silent_redirect_uri: `${window.location.origin}/silent-check-sso.html`,
      onSigninCallback: () => {
        const redirectParams = getRedirectParams();

        navigate(redirectParams, { replace: true });
      },
    };
  }, [authData]);

  return (
    <AuthProvider {...oidcConfig}>
      <AuthOIDC {...props} />
    </AuthProvider>
  );
};
