import {
  UiBadge,
  UiCard,
  UiCol,
  UiDivider,
  UiDrawer,
  UiNavbar,
  UiRow,
  UiSkeleton,
  UiSpace,
  UiTabs,
} from '@vkph/ui';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { useAbstractStorage } from '@vkph/common/hooks';
import { profileFullInfoStorage } from '@vkph/common/store/profile';
import { UserIdModel } from '@vkph/common/types/models';
import { declenseFirstName, DeclensionCases } from '@vkph/common/utils/declension';
import { useSpace, useToggle } from '@vkph/ui/hooks';
import { SocialsInfoType, getTimelinesAllRelationsCounterStorage } from '~profile/store/timelines';

import { relationLabel } from '../constants';
import { FollowList } from '../profile/follow-list/FollowList';
import { RelationButton } from './relation-button/RelationButton';

const RELATION_BUTTON_MOCK = <UiSkeleton loading height={40} width="100%" />;

export const RelationsCard: FC = () => {
  const { id = '' } = useParams<{ id: UserIdModel }>();
  const { spaceM } = useSpace();
  const [isRelationsModalOpen, toggleIsRelationsModalOpen] = useToggle([false, true]);
  const [socialsInfoType, setSocialsInfoType] = useState<SocialsInfoType>(SocialsInfoType.Followers);

  const setSocialsInfoAction = useCallback((type: SocialsInfoType) => {
    setSocialsInfoType(type);
    toggleIsRelationsModalOpen();
  }, []);

  const timelinesAllRelationsCounterStorage = useMemo(getTimelinesAllRelationsCounterStorage, []);
  const { data: allRelationsCounterData, loading: isAllRelationsCounterDataLoading } = useAbstractStorage(
    timelinesAllRelationsCounterStorage.storage,
    {
      autoFetchAndRefetch: true,
      autoFetchParams: { userId: id },
    },
  );
  const { followers: followersCount, followings: followingsCount } = allRelationsCounterData;

  const { data: profileFullInfoData } = useAbstractStorage(profileFullInfoStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: { userId: id },
  });

  const relationsModalTitle = useMemo(() => {
    if (profileFullInfoData) {
      const { fullName, gender } = profileFullInfoData.main;

      return `${relationLabel[SocialsInfoType.Followers]} ${declenseFirstName({
        name: fullName.firstName,
        gender,
        declination: DeclensionCases.Genitive,
      })}`;
    }

    return <UiSkeleton loading height={20} width="100%" />;
  }, [profileFullInfoData]);

  const profileMenuItems = useMemo(
    () => [
      {
        key: SocialsInfoType.Followers,
        label: (
          <>
            {relationLabel[SocialsInfoType.Followers]}
            <UiBadge count={followersCount} overflowCount={999} />
          </>
        ),
      },
      {
        key: SocialsInfoType.Followings,
        label: (
          <>
            {relationLabel[SocialsInfoType.Followings]}
            <UiBadge count={followingsCount} overflowCount={999} />
          </>
        ),
      },
    ],
    [allRelationsCounterData],
  );

  return (
    <>
      <UiCard size="default" styles={{ body: { padding: spaceM } }}>
        <UiRow gutter={[spaceM, 0]}>
          <UiCol flex="1">
            {isAllRelationsCounterDataLoading && RELATION_BUTTON_MOCK}
            {!isAllRelationsCounterDataLoading && (
              <RelationButton
                onClick={() => setSocialsInfoAction(SocialsInfoType.Followers)}
                count={followersCount}
                type={SocialsInfoType.Followers}
                userId={id}
              />
            )}
          </UiCol>
          <UiCol>
            <UiDivider emptyMargin type="vertical" style={{ height: '100%' }} />
          </UiCol>
          <UiCol flex="1">
            {isAllRelationsCounterDataLoading && RELATION_BUTTON_MOCK}
            {!isAllRelationsCounterDataLoading && (
              <RelationButton
                onClick={() => setSocialsInfoAction(SocialsInfoType.Followings)}
                count={followingsCount}
                type={SocialsInfoType.Followings}
                userId={id}
              />
            )}
          </UiCol>
        </UiRow>
      </UiCard>

      <UiDrawer
        open={isRelationsModalOpen}
        styles={{ header: { padding: 0 }, body: { padding: 0 } }}
        closable={false}
        width="100%"
        title={
          <UiSpace direction="vertical" size={0} full>
            <UiNavbar title={relationsModalTitle} onBack={toggleIsRelationsModalOpen} bordered />
            <UiTabs
              defaultActiveKey={socialsInfoType}
              activeKey={socialsInfoType}
              items={profileMenuItems}
              onChange={(key) => setSocialsInfoType(key as SocialsInfoType)}
              size="small"
              full
            />
          </UiSpace>
        }
      >
        <FollowList
          type={socialsInfoType}
          onClose={toggleIsRelationsModalOpen}
          counterStorage={timelinesAllRelationsCounterStorage}
          userName={profileFullInfoData?.main?.fullName?.firstName}
        />
      </UiDrawer>
    </>
  );
};
