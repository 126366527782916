import omit from 'lodash/omit';
import qs, { stringify } from 'query-string';
import { Location } from 'react-router-dom';

/**
 * Не заменять history.replace из react-router 5 на нативный history.replaceState, будут проблемы с редиректом
 * Нативный replaceState игнорируется react-router в ряде случаев (см. B2BCORE-5057)
 */

export const getRedirectParams = (): Pick<Location, 'pathname' | 'search'> => {
  const userParamsUrl = stringify(omit(qs.parse(window.location.search), ['state', 'session_state', 'code'])); // TODO хак из офф документации, надо бы создать issue в github чтобы они сами удаляли параметры, потому что редирект может быть с нужными парамерами от пользователя
  const search = userParamsUrl ? `?${userParamsUrl}` : '';

  return {
    pathname: window.location.pathname,
    search,
  };
};
