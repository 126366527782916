import { UiUser, LinkListItem } from '@vkph/components';
import {
  UiTypography,
  UiButton,
  UiEmpty,
  UiTruncateMarkup,
  UiDivider,
  UiRow,
  UiList,
  UiModal,
  UiModalTypes,
} from '@vkph/ui';
import React, { useMemo, useState } from 'react';

import { useAbstractStorage } from '@vkph/common/hooks';
import { getBlogMembersStorage } from '@vkph/common/store/blogs';
import {
  createArrayMock,
  getRoutePath,
  RouteNames,
  getFullNameWithoutPatronymic,
  getFormattedDate,
} from '@vkph/common/utils';
import { BlogWidgetComponent } from '~groups/components/blog/constants';

import { BlogWidgetNewParticipantsModal } from './modal/BlogWidgetNewParticipantsModal';

export const BlogWidgetNewParticipants: BlogWidgetComponent = (props) => {
  const { blog } = props;
  const { id } = blog;
  const usersToShow = 10;
  const [isAllParticipantsOpen, setIsAllParticipantsOpen] = useState(false);

  const blogParticipantsStorage = useMemo(() => getBlogMembersStorage(id), [id]);

  const {
    data: participants,
    loading,
    error,
    pagination: { count: totalUsers },
  } = useAbstractStorage(blogParticipantsStorage.storage, {
    autoFetchAndRefetch: true,
    autoFetchParams: {
      pageNumber: 1,
      pageSize: usersToShow,
    },
  });

  const showMoreButton = totalUsers && totalUsers > usersToShow;
  const isErrorOrNotFound = !loading && (error || participants.length === 0);

  return (
    <>
      <UiDivider emptyMargin />
      {isErrorOrNotFound && <UiEmpty.Feed emptyMessage={{ header: 'Нет данных об участниках' }} />}
      {loading && createArrayMock(usersToShow, (_, key) => <LinkListItem.Skeleton key={key} />)}
      {!loading && (
        <>
          <UiList
            split={false}
            dataSource={participants}
            renderItem={(participant) => {
              const joinedDate = `Присоединился к группе ${getFormattedDate(
                participant.joinedAt,
                'dateTime',
              )}`;

              return (
                <UiList.Item style={{ padding: '8px 16px' }}>
                  <UiUser.Info
                    avatarProps={{ src: participant.avatar, size: 40 }}
                    isActive={participant.isActive}
                    to={getRoutePath(RouteNames.Profile, { id: participant.keycloakId })}
                    title={getFullNameWithoutPatronymic(participant)}
                    subtitle={
                      <UiTypography.Footnote type="secondary">
                        <UiTruncateMarkup truncate tooltipProps={{ title: joinedDate }}>
                          {joinedDate}
                        </UiTruncateMarkup>
                      </UiTypography.Footnote>
                    }
                  />
                </UiList.Item>
              );
            }}
          />
          {showMoreButton && (
            <>
              <UiRow padding={[16, 0]} align="middle" justify="center">
                <UiButton type="link" label="Показать всех" onClick={() => setIsAllParticipantsOpen(true)} />
              </UiRow>
              <UiModal
                type={UiModalTypes.Large}
                isOpen={isAllParticipantsOpen}
                onClose={() => setIsAllParticipantsOpen(false)}
              >
                <BlogWidgetNewParticipantsModal blog={blog} />
              </UiModal>
            </>
          )}
        </>
      )}
    </>
  );
};
