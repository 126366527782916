import { UiCard, UiIcon, UiSpace } from '@vkph/ui';
import { isSameDay } from 'date-fns';
import React, { FC, useMemo } from 'react';

import { SubstitutionType } from '@vkph/common/types';
import { SubstitutionModel } from '@vkph/common/types/models';
import { getFormattedDate } from '@vkph/common/utils';
import { variables } from '@vkph/ui/providers/theme';
import SickBig from '@vkph/ui/svg/sick-big.svg';
import TripBig from '@vkph/ui/svg/trip-big.svg';
import VacationBig from '@vkph/ui/svg/vacation-big.svg';

type Substitution = {
  icon: SvgrComponent;
  text: string;
  backgroundColor: string;
};

type Props = {
  substitution: SubstitutionModel;
};

export const AbsenceHeader: FC<Props> = (props) => {
  const { substitution } = props;

  const renderSubstitutionType: Record<string, Substitution> = {
    [SubstitutionType.Illness]: {
      icon: SickBig,
      text: 'На больничном',
      backgroundColor: variables.themePalette.colorAccidentGold,
    },
    [SubstitutionType.BusinessTrip]: {
      icon: TripBig,
      text: 'В командировке',
      backgroundColor: variables.themePalette.colorAccidentBlueDark,
    },
    [SubstitutionType.Vacation]: {
      icon: VacationBig,
      text: 'В отпуске',
      backgroundColor: variables.themePalette.colorAccidentBlue2,
    },
  };
  const { icon, text, backgroundColor } = renderSubstitutionType[substitution.type];

  const dateRange = useMemo(() => {
    const isSameDayInRange = isSameDay(new Date(substitution.since), new Date(substitution.till));

    if (isSameDayInRange) {
      return getFormattedDate(substitution.since, 'date');
    }

    const sinceFormat = getFormattedDate(substitution.since, 'date');
    const tillFormat = getFormattedDate(substitution.till, 'date');

    return `с ${sinceFormat} по ${tillFormat}`;
  }, [substitution.since, substitution.till]);

  return (
    <UiCard.Header style={{ backgroundColor, padding: 20 }}>
      <UiSpace full size="large">
        <UiIcon width={40} height={40} component={icon} color={variables.themePalette.colorTextInvert} />
        <UiSpace size={0} direction="vertical">
          <UiCard.Header.Title level={2} style={{ color: variables.themePalette.colorTextInvert }}>
            {text}
          </UiCard.Header.Title>
          <UiCard.Header.Title level={2} style={{ color: variables.themePalette.colorTextInvert }}>
            {dateRange}
          </UiCard.Header.Title>
        </UiSpace>
      </UiSpace>
    </UiCard.Header>
  );
};
