import { UiUser } from '@vkph/components';
import { UiSpace, UiTypography } from '@vkph/ui';
import React, { FC } from 'react';

import { BlogLastActivityModel, BlogModel } from '@vkph/common/types/models';
import {
  getFullNameWithPatronymic,
  getRoutePath,
  RouteNames,
  getFormattedRelativeDate,
} from '@vkph/common/utils';

import { BlogWidgetLastActivityListItemWrapper } from './wrapper/BlogWidgetLastActivityListItemWrapper';

export interface BlogWidgetLastActivityListItemProps extends Required<BlogLastActivityModel> {
  blog: BlogModel;
}

export const BlogWidgetLastActivityListItem: FC<BlogWidgetLastActivityListItemProps> = (props) => {
  const { blog, actor, createdAt, object, summary: actionText } = props;
  const { summary: objectSummary } = object;
  const { avatar, isActive, keycloakId } = actor;

  return (
    <UiSpace size={0} direction="vertical" full style={{ padding: '0px 24px 16px' }}>
      <UiUser.Info
        to={getRoutePath(RouteNames.Profile, { id: keycloakId })}
        title={getFullNameWithPatronymic(actor)}
        subtitle={
          <UiSpace direction="vertical" size={0}>
            <UiTypography.Footnote type="secondary">
              {getFormattedRelativeDate(createdAt, 'dateTime')}
            </UiTypography.Footnote>
            <UiTypography.Text type="secondary">
              {actionText} {objectSummary}
            </UiTypography.Text>
            <BlogWidgetLastActivityListItemWrapper object={object} blog={blog} />
          </UiSpace>
        }
        avatarProps={{ size: 'middle', src: avatar }}
        isActive={isActive}
      />
    </UiSpace>
  );
};
